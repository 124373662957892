import ReportTable from "components/Report";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	Card,
	CardBody,
	Col,
	Modal,
	ModalBody,
	ModalHeader,
	Row
} from "reactstrap";
import * as api from "services/api";
import { getCompanyById } from "../actions";
import { toast } from "react-toastify";
import DigisacEditFrom from "./digisacEditFrom";

const DigisacIntegration = () => {
	const dispatch = useDispatch();
	const table = useRef(null);

	const { item: company } = useSelector(state => state.companyDetail);
	const { token } = useSelector(state => state.login);

	const digisac_edit_form = useSelector(state => state.digisac_edit_form);

	const [connectionTypesData, setConnectionTypesData] = useState([]);
	const [hireds, setHireds] = useState([]);
	const [count, setCount] = useState(0);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(10);
	const [digisacAttendants, setDigisacAttendants] = useState([]);
	const [onboardAttendant, setOnboardAttendant] = useState({});
	const [ongoingAttendant, setOngoingAttendant] = useState({});

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [edit, setEdit] = useState(false);

	const [modalActivate, setModalActivate] = useState(false);
	const toggleModalActivate = () => setModalActivate(!modalActivate);

	const initialValues = {
		connectionType: {
			value: company?.connectionType,
			label: company?.connectionType
		},
		hasMessageAutomationBlocked: company?.hasMessageAutomationBlocked,
		onboardAttendant: {
			value: onboardAttendant?._id,
			label: onboardAttendant?.name
		},
		ongoingAttendant: {
			value: ongoingAttendant?._id,
			label: ongoingAttendant?.name
		}
	};

	const handleSubmitEdit = async data => {
		if (!data.onboardAttendant || !data.ongoingAttendant) {
			toast.warning(
				"Por favor, selecione os atendentes de Onboard e Ongoing!",
				{
					onClose: () => {
						setEdit(false);
					},
					autoClose: 5000
				}
			);

			return;
		}

		const toUpdate = {
			...company,
			connectionType: data.connectionType?.value,
			onboardAttendantId: data.onboardAttendant?.value,
			ongoingAttendantId: data.ongoingAttendant?.value
		};

		const response = await api.sendPut(`/company/${company._id}`, toUpdate, {
			Authorization: "Bearer " + token
		});

		if (response.status === 200 || response.status === 201) {
			toast.success("Dados da empresa salvos com sucesso!", {
				onClose: async () => {
					await fetchDigisacAttendantsByCompany();

					dispatch(
						getCompanyById(company._id, err => {
							if (err) {
								console.log(err);
							} else {
								setEdit(false);
							}
						})
					);
				}
			});
		} else {
			toast.error("Houve um erro ao salvar dados da empresa!", {
				onClose: () => {
					setEdit(false);
				}
			});
		}
	};

	const handleSubmitChangeMessageAutomation = async () => {
		setIsSubmitting(true);
		const response = await api.sendPut(
			`/company/change-message-automation/${company._id}`,
			{},
			{
				Authorization: "Bearer " + token
			}
		);

		if (!response) {
			console.log("Erro ao buscar tipos de conexão");
			return;
		}

		const result = await response.json();

		if (result.isValid) {
			toggleModalActivate();
			toast.success(
				result.model?.message || "Dados da empresa salvos com sucesso!",
				{
					onClose: () => {
						dispatch(
							getCompanyById(company._id, err => {
								if (err) {
									console.log(err);
								}
							})
						);
						setIsSubmitting(false);
					}
				}
			);
		} else {
			console.log(
				result.errors?.message || "Houve um erro ao salvar dados da empresa!"
			);

			toggleModalActivate();
		}
	};

	const fetchHireds = async tableState => {
		if (!tableState) return;
		const { page, pageSize } = tableState;

		const response = await api.sendGet(
			`/companyProvider/contracted-company-report?skip=${page *
				pageSize}&take=${pageSize}&sort=createdAt&desc=true&business=${
				company._id
			}&filters[providerUser.needToSendMessage]=true`,
			{ Authorization: "Bearer " + token }
		);

		if (!response) {
			console.log("Erro ao buscar tipos de conexão");
			return;
		}

		const result = await response.json();

		if (result.isValid) {
			setHireds(result.model);
			setCount(result.params.count);
			setSkip(Math.ceil(result.params.count / take));
			setTake(pageSize);
		}
	};

	const fetchConnectionTypes = async () => {
		const response = await api.sendGet(`/digisacTemplate/templateConnection`, {
			Authorization: "Bearer " + token
		});

		if (!response) {
			console.log("Erro ao buscar tipos de conexão");
			return;
		}

		const result = await response.json();

		if (result.isValid) {
			setConnectionTypesData(
				result.model.map(connection => {
					return {
						value: connection,
						label: connection
					};
				})
			);
		}
	};

	const fetchAllDigisacAttendants = async () => {
		const response = await api.sendGet(`/digisacAttendants`, {
			Authorization: "Bearer " + token
		});

		const result = await response.json();

		if (result.isValid) {
			setDigisacAttendants(
				result.model.map(attendant => {
					return {
						value: attendant._id,
						label: attendant.name
					};
				})
			);
		}
	};

	const fetchDigisacAttendantsByCompany = async () => {
		try {
			const response = await api.sendGet(
				`/v2/onboard/find-onboard-by-company-id/${company._id}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				if (result.model?.digisacOnboardAttendantId) {
					const onboardResponse = await api.sendGet(
						`/digisacAttendants/${result.model.digisacOnboardAttendantId}`,
						{
							Authorization: "Bearer " + token
						}
					);

					const onboardResult = await onboardResponse.json();

					if (onboardResult.isValid) {
						setOnboardAttendant(onboardResult.model);
					}
				}

				if (result.model?.digisacOngoingAttendantId) {
					const ongoingResponse = await api.sendGet(
						`/digisacAttendants/${result.model.digisacOngoingAttendantId}`,
						{
							Authorization: "Bearer " + token
						}
					);

					const ongoingResult = await ongoingResponse.json();

					if (ongoingResult.isValid) {
						setOngoingAttendant(ongoingResult.model);
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchConnectionTypes();
		fetchDigisacAttendantsByCompany();
		fetchAllDigisacAttendants();
	}, []);

	return (
		<Card>
			<CardBody>
				<Row id="digisac-integration-title">
					<Col>
						<h4>
							<strong>Integração Digisac</strong>
						</h4>
					</Col>
				</Row>

				<div className="topbar__menu-divider" />

				<Row id="digisac-integration" className="mt-3">
					<Col>
						<p>
							<strong>Possui Mensagem Automática Bloqueada:</strong>
							<br />
							<span>
								{company?.hasMessageAutomationBlocked
									? "Bloqueada"
									: "Liberada"}
							</span>
						</p>
					</Col>

					<Col className="text-right">
						<Button
							color="primary"
							onClick={() => {
								toggleModalActivate();
								fetchHireds();
							}}
						>
							{company.hasMessageAutomationBlocked ? "Liberar" : "Bloquear"}{" "}
							Mensagens
						</Button>
					</Col>
				</Row>

				<div className="topbar__menu-divider" />

				{edit ? (
					<DigisacEditFrom
						onSubmit={handleSubmitEdit}
						initialValues={initialValues}
						isInvalid={digisac_edit_form && !!digisac_edit_form.syncErrors}
						onCancel={() => setEdit(false)}
						connectionTypesData={connectionTypesData}
						digisacAttendants={digisacAttendants}
					/>
				) : (
					<>
						<Row className="mt-3">
							<Col>
								<p>
									<strong>Tipo de Conexão:</strong>
									<br />
									<span>{company?.connectionType || ""}</span>
								</p>
							</Col>
							<Col>
								<p>
									<strong>Atendente de Onboard:</strong>
									<br />
									<span>{onboardAttendant?.name || ""}</span>
								</p>
							</Col>
							<Col>
								<p>
									<strong>Atendente de Ongoing:</strong>
									<br />
									<span>{ongoingAttendant?.name || ""}</span>
								</p>
							</Col>

							<Col className="text-right">
								<Button color="primary" onClick={() => setEdit(true)}>
									Editar
								</Button>
							</Col>
						</Row>
					</>
				)}

				<Modal isOpen={modalActivate} toggle={toggleModalActivate} size="lg">
					<ModalHeader
						toggle={toggleModalActivate}
						className={"card__title bold-text m-0"}
					>
						<strong>
							{company.hasMessageAutomationBlocked ? "Liberar" : "Bloquear"}{" "}
							Mensagens
						</strong>
					</ModalHeader>

					{company?.hasMessageAutomationBlocked ? (
						<ModalBody>
							<p className="h6">Deseja liberar as mensagens automáticas?</p>
							<p>
								<strong className="text-danger">
									Esta ação não pode ser desfeita
								</strong>
								, pois o sistema enviará automaticamente as mensagens para os
								prestadores assim que liberada
							</p>

							<p className="h6">
								Serão <strong>{count}</strong> prestadores que receberão as
								mensagens:
							</p>

							<ReportTable
								manual
								tableRef={table}
								noTitle={true}
								data={hireds}
								pageSize={take}
								pages={skip}
								count={count}
								noExport={true}
								onFetchData={fetchHireds}
								columns={[
									{
										Header: "Nome",
										id: "name",
										accessor: ({ providerUser }) => providerUser?.name
									}
								]}
							/>

							<div className="d-flex justify-content-between">
								<Button type="button" onClick={toggleModalActivate}>
									Cancelar
								</Button>
								<Button
									color="primary"
									onClick={handleSubmitChangeMessageAutomation}
									disabled={isSubmitting}
								>
									Liberar
								</Button>
							</div>
						</ModalBody>
					) : (
						<ModalBody>
							<p className="h6">
								Deseja <strong>BLOQUEAR</strong> novamente as mensagens
								automáticas?
							</p>

							<p>
								Ao fazer isso os próximos prestadores que forem adicionados não
								serão notificados até que as mensagens automáricas sejam
								liberadas novamente
							</p>

							<div className="d-flex justify-content-between mt-3">
								<Button type="button" onClick={toggleModalActivate}>
									Cancelar
								</Button>
								<Button
									color="primary"
									onClick={handleSubmitChangeMessageAutomation}
									disabled={isSubmitting}
								>
									Bloquear
								</Button>
							</div>
						</ModalBody>
					)}
				</Modal>
			</CardBody>
		</Card>
	);
};

export default DigisacIntegration;
